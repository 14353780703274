import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import Helpers from "../utils/Helpers";
import { Button, ButtonGroup, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Grid, _ } from "gridjs-react";

export default function GateOutPage() {
  const navigate = useNavigate();

  const role = localStorage.getItem("role");

  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const [start, setStart] = useState(Helpers.formatDate(oneYearAgo));
  const [end, setEnd] = useState(Helpers.formatDate(today));
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    axios.get(`/gate-out?start=${start}&end=${end}`).then((response) => {
      setData(response.data);
    });
  }, []);

  const handleCreate = () => {
    navigate('/gate-out/submit');
  }

  const handleDelete = (id) => {
    const confirm = window.confirm('Are you sure you want to delete this record?');
    if (!confirm) return;

    axios.delete(`/gate-out/${id}`).then((response) => {
      setData(data.filter((item) => item.id != id));
    }).catch((error) => {
      alert(Helpers.parseErrorMessages(error));
    });
  }

  return (
    <>
      <Header title="Gate Out" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Gate Out</li>
            </ol>
            <h4 className="main-title mb-0">Gate Out Data</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={handleCreate}>
              <i className="ri-add-line fs-18 lh-1"></i>Gate Check Out
            </Button>
          </div>
        </div>

        <div className="d-flex overflow-auto">
          <Grid
            data={data}
            columns={[
              { id:"id", name:"id", hidden: true },
              {
                id: 'actions',
                hidden: role != 'Super Admin' && role != 'Admin',
                name: '', sort: false, formatter: (cell, row) => _(
                  <ButtonGroup>
                    <Button variant="" className="btn-white text-danger" size="sm" onClick={() => handleDelete(row.cells[0].data)}>Delete</Button>
                  </ButtonGroup>
                ),
                width: '100px'
              },
              { id:"timestamp", name:"Timestamp", formatter: (cell, row) => _(Helpers.displayDateTime(cell + "Z")) },
              { id:"gateName", name:"Gate" },
              { id:"name", name:"Name" },
              { id:"position", name:"Position" },
              { id:"companyName", name:"Company" },
            ]}
            search={true}
            pagination={true}
            sort={true}
            className={{
              table: 'table table-bordered mb-0'
            }}
          />
        </div>

        <Footer />
      </div>
    </>
  )
}