import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axios';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import QRCode from "react-qr-code";

export default function EditGatePage() {
  const params = useParams();
  const navigate = useNavigate();

  const [mode, setMode] = React.useState("Create");
  const [name, setName] = React.useState("");
  const [gateQr, setGateQr] = React.useState("");

  React.useEffect(() => {
    if (params.id) {
      setMode("Update");
      setGateQr(`${window.location.origin}/gate-in/submit/${params.id}`);

      axios.get(`/gates/${params.id}`)
        .then((response) => {
          setName(response.data.name);
        });
    } else {
      setMode("Create");
    }
  }, [params.id]);

  const handleSave = async () => {
    const request = {
      name: name
    };

    try {
      if (mode == "Create") {
        await axios.post("/gates", request);
      } else {
        await axios.put(`/gates/${params.id}`, request);
      }

      navigate('/gate');
    } catch (error) {
      alert('Failed to save gate data: ' + error.response.data.message || error.response.data || error.response);
    }
  }

  const handleCancel = () => {
    navigate('/gate');
  }

  const handleDownloadQr = () => {
    const svg = document.getElementById("QRCode");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }

  return (
    <>
      <Header title={`${mode} Gate`} />

      <div className="main main-app p-3 p-lg-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><Link to="/gate">Gate</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{mode}</li>
          </ol>
          <h4 className="main-title mb-0">{`${mode} Gate`}</h4>
        </div>

        <Row className="mt-4">
          <Col md={6}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Gate Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter gate name" value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col>
                <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
                <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={3} md={6}>
            { mode == "Update" && 
              <QRCode
              id="QRCode"
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={gateQr}
              viewBox={`0 0 256 256`}
              />
            }
          </Col>
          <Col xs={12} className="mt-2"><small><a href={gateQr}>{gateQr}</a></small></Col>
          <Col xs={12}>
            <Button className="btn btn-secondary" onClick={handleDownloadQr}>Download QR</Button>
          </Col>
        </Row>
        <Footer />
      </div>
    </>
  )
}