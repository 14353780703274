import React from 'react';
import { Navigate, useLocation  } from 'react-router-dom';

export default function AuthGuard({ children }) {
  const location = useLocation();

  const isAuthenticated = localStorage.getItem("accessToken") ? true : false;
  const expired = localStorage.getItem("expired");
  if (!isAuthenticated || (expired && expired < Date.now())) {
    window.redirectTo = location.pathname;
    localStorage.clear();
    return <Navigate to="/login" />;
  }
  
  return children;
}