import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { Button, ButtonGroup } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Grid, _ } from "gridjs-react";
import Helpers from "../utils/Helpers";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import '@inovua/reactdatagrid-community/index.css';

export default function UserPage() {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);

  const columns = [
    {
      name: 'id',
      header: 'Action',
      defaultWidth: 150,
      render: ({ value, data }) => (
        <ButtonGroup>
          <Button variant="" className="btn-white" size="sm" onClick={() => handleUpdate(value)}>Update</Button>
          <Button variant="" className="btn-white text-danger" size="sm" onClick={() => handleDelete(value)}>Delete</Button>
        </ButtonGroup>
      )
    },
    { name: 'username', header: 'Username', defaultWidth: 150, render: ({value, data}) => (<Link to={`/user/view/${data.id}`}>{value}</Link>) },
    { name: 'role', header: 'Role', defaultWidth: 150 },
    {
      name: 'screeningStatus',
      header: 'Status',
      defaultWidth: 180,
      render: ({ value, data }) => (
        value == "Permitted to work"
          ? <span className="badge text-white text-bg-success fs-6">{value}</span>
          : <span className="badge text-white text-bg-danger fs-6">{value}</span>)
    },
    { name: 'name', header: 'Name', defaultWidth: 200 },
    { name: 'defaultGateName', header: 'Default Gate', defaultWidth: 180 },
    { name: 'companyName', header: 'Company Name', defaultWidth: 180 },
    { name: 'position', header: 'Position', defaultWidth: 180 },
    { name: 'birthPlace', header: 'Birth Place', defaultWidth: 180 },
    {
      name: 'birthDate',
      header: 'Birth Date',
      defaultWidth: 150,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'DD/MM/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
        }
      },
      render: ({ value, data }) => (Helpers.displayDate(value)),
    },
    { name: "gender", header: "Gender", defaultWidth: 100 },
    { name: "phoneNumber", header: "Phone No", defaultWidth: 180 },
    { name: "address", header: "Address", defaultWidth: 180 },
    { name: "bloodType", header: "Blood Type", defaultWidth: 180 },
    { name: "nationality", header: "Nationality", defaultWidth: 180 },
    { name: "mcuVerificationNumber", header: "MCU Verification No", defaultWidth: 180 },
    { 
      name: "mcuResult", 
      header: "MCU Result", 
      defaultWidth: 180,
      render: ({ value, data }) => (
        value == "Unfit" 
          ? <span className="badge text-white text-bg-danger fs-6">{value}</span> 
          : <span className="badge text-white text-bg-success fs-6">{value}</span>
      )
     },
    { name: "mcuNote", header: "MCU Note", defaultWidth: 180 },
    { 
      name: "mcuValidation",
      header: "MCU Validation", 
      defaultWidth: 150, 
      filterEditor: DateFilter, 
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'DD/MM/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
        }
      },
      render: ({ value, data }) => (Helpers.displayDate(value))
    },
    { name: "bpjsTk", header: "BPJS TK", defaultWidth: 180 },
    { name: "bpjsKesehatan", header: "BPJS Kesehatan", defaultWidth: 180 },
    { name: "skck", header: "SKCK" },
    { name: "skckValidation", header: "SKCK Validation", render: (value, data) => (Helpers.displayDate(value)) },
    { 
      name: "startDuty",
      header: "Start Duty", 
      defaultWidth: 150, 
      filterEditor: DateFilter, 
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'DD/MM/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
        }
      },
      render: ({ value, data }) => (Helpers.displayDate(value))
    },
    { 
      name: "endDuty",
      header: "End Duty", 
      defaultWidth: 150, 
      filterEditor: DateFilter, 
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'DD/MM/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
        }
      },
      render: ({ value, data }) => (Helpers.displayDate(value))
    },
    { name: "adminApprovalStatus", header: "Admin Approval" },
    { name: "doctorApprovalStatus", header: "Doctor Approval" },
    { name: "active", header: "Active", filterEditor: BoolFilter, render: (value, data) => (value ? "Yes" : "No") },
  ];

  const filterValue = [
    { name: 'username', operator: 'contains', type: 'string', value: '' },
    { name: 'role', operator: 'contains', type: 'string', value: '' },
    { name: 'screeningStatus', operator: 'startsWith', type: 'string', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'defaultGateName', operator: 'contains', type: 'string', value: '' },
    { name: 'companyName', operator: 'contains', type: 'string', value: '' },
    { name: 'position', operator: 'contains', type: 'string', value: '' },
    { name: 'birthPlace', operator: 'contains', type: 'string', value: '' },
    { name: 'birthDate', operator: 'before', type: 'date', value: '' },
    { name: 'gender', operator: 'startsWith', type: 'string', value: '' },
    { name: 'phoneNumber', operator: 'contains', type: 'string', value: '' },
    { name: 'address', operator: 'contains', type: 'string', value: '' },
    { name: 'bloodType', operator: 'eq', type: 'string', value: '' },
    { name: 'nationality', operator: 'contains', type: 'string', value: '' },
    { name: 'mcuVerificationNumber', operator: 'contains', type: 'string', value: '' },
    { name: 'mcuResult', operator: 'contains', type: 'string', value: '' },
    { name: 'mcuNote', operator: 'contains', type: 'string', value: '' },
    { name: 'mcuValidation', operator: 'contains', type: 'string', value: '' },
    { name: 'bpjsTk', operator: 'contains', type: 'string', value: '' },
    { name: 'bpjsKesehatan', operator: 'contains', type: 'string', value: '' },
    { name: "skck", operator: 'contains', type: 'string', value: '' },
    { name: "skckValidation", operator: 'contains', type: 'string', value: '' },
    { name: "startDuty", operator: 'contains', type: 'string', value: '' },
    { name: "endDuty", operator: 'contains', type: 'string', value: '' },
    { name: "adminApprovalStatus", operator: 'contains', type: 'string', value: '' },
    { name: "doctorApprovalStatus", operator: 'contains', type: 'string', value: '' },
  ];

  const gridStyle = { minHeight: 600 };

  React.useEffect(() => {
    axios.get('/users').then((response) => {
      setData(response.data);
    });
  }, []);

  const handleCreate = () => {
    navigate('/user/create');
  }

  const handleUpdate = (id) => {
    navigate(`/user/update/${id}`);
  }

  const handleDelete = (id) => {
    const confirm = window.confirm('Are you sure you want to delete this user?');
    if (!confirm) return;

    axios.delete(`/users/${id}`).then((response) => {
      setData(data.filter((item) => item.id !== id));
    });
  }

  return (
    <>
      <Header title="User" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">User</li>
            </ol>
            <h4 className="main-title mb-0">User Data</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/user/batch">
              <Button variant="secondary" className="d-flex align-items-center gap-2">
                <i className="ri-file-list-line fs-18 lh-1"></i>Batch Update
              </Button>
            </Link>
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={handleCreate}>
              <i className="ri-add-line fs-18 lh-1"></i>Create New User
            </Button>
          </div>
        </div>

        <div className='mt-3'>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={data}
            defaultFilterValue={filterValue}
            pagination={true}
            style={gridStyle}
            enableColumnAutosize={true}
          />
        </div>

        <Footer />
      </div>
    </>
  )
}