import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { Button, ButtonGroup, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Grid, _ } from "gridjs-react";

export default function GatePage() {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.get('/gates').then((response) => {
      setData(response.data);
    });
  }, []);

  const handleCreate = () => {
    navigate('/gate/create');
  }

  const handleUpdate = (id) => {
    navigate(`/gate/update/${id}`);
  }

  const handleDelete = (id) => {
    const confirm = window.confirm('Are you sure you want to delete this gate?');
    if (!confirm) return;

    axios.delete(`/gates/${id}`).then((response) => {
      setData(data.filter((item) => item.id != id));
    });
  }

  return (
    <>
      <Header title="Gate" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Gate</li>
            </ol>
            <h4 className="main-title mb-0">Gate Data</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={handleCreate}>
              <i className="ri-add-line fs-18 lh-1"></i>Create New Gate
            </Button>
          </div>
        </div>

        <div className="d-flex overflow-auto">
          <Grid
            data={data}
            columns={[
              { id:"id", name:"id", hidden:true },
              {
                id: 'actions',
                name: '', sort: false, formatter: (cell, row) => _(
                  <ButtonGroup>
                    <Button variant="" className="btn-white" size="sm" onClick={() => handleUpdate(row.cells[0].data)}>Update</Button>
                    <Button variant="" className="btn-white text-danger" size="sm" onClick={() => handleDelete(row.cells[0].data)}>Delete</Button>
                  </ButtonGroup>
                ),
                width: '150px',
              },
              { id:"name", name:"Gate Name", },
            ]}
            autoWidth={true}
            search={true}
            pagination={true}
            sort={true}
            className={{
              table: 'table table-bordered mb-0'
            }}
          />
        </div>

        <Footer />
      </div>
    </>
  )
}