import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axios';
import Helpers from '../utils/Helpers';
import { Row, Col, Form, Card, Button, Tabs, Tab } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function UpdateMedicalPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [username, setUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = React.useState("");
  const [bloodType, setBloodType] = React.useState("");
  const [mcuVerificationNumber, setMcuVerificationNumber] = React.useState("");
  const [mcuResult, setMcuResult] = React.useState("");
  const [mcuNote, setMcuNote] = React.useState("");
  const [mcuValidation, setMcuValidation] = React.useState(null);
  const [mcuDocument, setMcuDocument] = React.useState("");
  const [bpjsTk, setBpjsTk] = React.useState("");
  const [bpjsKesehatan, setBpjsKesehatan] = React.useState("");

  const [mcuDocumentUrl, setMcuDocumentUrl] = React.useState(null);
  const [mcuDocumentFile, setMcuDocumentFile] = React.useState(null);
  const [mcuDocumentHistory, setMcuDocumentHistory] = React.useState([]);

  React.useEffect(() => {
    if (params.id) {
      axios.get(`/users/${params.id}`)
        .then((response) => {
          setUsername(response.data.username);
          setName(response.data.name);
          setCompanyName(response.data.companyName);
          setPosition(response.data.position);
          setBirthDate(Helpers.formatDate(response.data.birthDate + "Z"));
          setGender(response.data.gender);
          setBloodType(response.data.bloodType);
          setMcuVerificationNumber(response.data.mcuVerificationNumber);
          setMcuResult(response.data.mcuResult);
          setMcuNote(response.data.mcuNote);
          setMcuValidation(Helpers.formatDate(response.data.mcuValidation));
          setMcuDocument(response.data.mcuDocument);
          setBpjsTk(response.data.bpjsTk);
          setBpjsKesehatan(response.data.bpjsKesehatan);

          setMcuDocumentUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.mcuDocument);
        });

      axios.get(`/documents/history?userId=${params.id}&type=1`)
        .then((response) => {
          setMcuDocumentHistory(response.data);
        });
    } else {
      navigate('/dashboard');
    }
  }, [params.id]);

  const handleSave = async () => {
    try {
      const request = {
        id: params.id,
        mcuVerificationNumber: mcuVerificationNumber,
        mcuResult: mcuResult,
        mcuNote: mcuNote,
        mcuValidation: mcuValidation,
      }

      const formDataHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if (mcuDocumentFile) {
        const formDataMcuDocument = new FormData();
        formDataMcuDocument.append('file', mcuDocumentFile);
        const responseMcuDocument = await axios.post('/documents/upload', formDataMcuDocument, formDataHeaders);
        request.mcuDocument = responseMcuDocument.data;
      }

      await axios.put(`/users/medical`, request);
      navigate('/dashboard');
    } catch (error) {
      alert(Helpers.parseErrorMessages(error));
    }
  }

  const handleCancel = () => {
    navigate('/dashboard');
  }

  const changeMcuDocumentFile = (e) => {
    setMcuDocumentFile(e.target.files[0]);
    setMcuDocumentUrl(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      <Header title={`Update Medical Information`} />

      <div className="main main-app p-3 p-lg-4">
        <div>
          <h4 className="main-title mb-0">Update Medical Info: <span className="text-secondary">{name}</span></h4>
        </div>

        <Card className="card-form mt-3">
          <Card.Body className="p-0">
            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Username</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={username} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Name</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={name} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Company</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={companyName} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Position</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={position} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Birth Date</h6>
                </Col>
                <Col md>
                  <Form.Control type="date" placeholder="Birth Date" value={birthDate} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Gender</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={gender} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Blood Type</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" value={bloodType} disabled={true} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>MCU Verification Number</h6>
                  <p>Medical check up verification number</p>
                </Col>
                <Col md>
                  <Form.Control type="text" value={mcuVerificationNumber} onChange={(e) => setMcuVerificationNumber(e.target.value)} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>MCU Result</h6>
                  <p>Medical check up result</p>
                </Col>
                <Col md>
                  <Form.Select value={mcuResult} onChange={(e) => setMcuResult(e.target.value)}>
                    <option value="">-- Select MCU Result --</option>
                    <option value="Fit">Fit</option>
                    <option value="Fit with note">Fit with note</option>
                    <option value="Fit with recommendation">Fit with recommendation</option>
                    <option value="Currently unfit">Currently unfit</option>
                    <option value="Unfit">Unfit</option>
                  </Form.Select>
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>MCU Note</h6>
                  <p>Medical check up note</p>
                </Col>
                <Col md>
                  <Form.Control as="textarea" rows="5" value={mcuNote} onChange={(e) => setMcuNote(e.target.value)} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>MCU Validation</h6>
                  <p>Medical valid date expiry</p>
                </Col>
                <Col md>
                  <Form.Control type="date" value={mcuValidation} onChange={(e) => setMcuValidation(e.target.value)} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>MCU Document</h6>
                  <p>Medical check-up document</p>
                </Col>
                <Col md>
                  <div>
                    <Form.Control type="file" onChange={(e) => changeMcuDocumentFile(e)} accept=".pdf,.docx" />
                  </div>
                  {
                    mcuDocumentHistory.map(x => (
                      <div className='mt-2' key={x.documentId}>
                        <a href={process.env.REACT_APP_BASE_URL + "/documents/" + x.documentId}>{Helpers.displayDate(x.date)} - {x.documentName}</a>
                      </div>
                    ))
                  }
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>BPJS TK</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="BPJS TK" value={bpjsTk} onChange={(e) => setBpjsTk(e.target.value)} />
                </Col>
              </Row>
            </div>

            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>BPJS Kesehatan</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="BPJS Kesehatan" value={bpjsKesehatan} onChange={(e) => setBpjsKesehatan(e.target.value)} />

                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        <Row className="mt-3">
          <Col>
            <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
            <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  )
}