import React from 'react';
import { Card, Col, Nav, Row } from "react-bootstrap";
import axios from '../utils/axios';
import userAvatar from "../assets/img/avatar.png";
import DoctorApproval from './DoctorApproval';
import { Link } from 'react-router-dom';

export default function DoctorDashboard() {
  const userId = localStorage.getItem("userId");
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")) ?? {});
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(userAvatar);
  const [summary, setSummary] = React.useState({});

  React.useState(() => {
    axios.get(`/users/${userId}`)
      .then(res => {
        setUser(res.data);
        if (res.data.passPhoto) {
          setPassPhotoUrl(process.env.REACT_APP_BASE_URL + "/documents/" + res.data.passPhoto);
        }
      }).catch(err => {
        console.log(err);
      });

    axios.get("/dashboard/summary")
      .then(res => {
        setSummary(res.data);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row className="g-3">

        <Col lg={4}>
          <Card className="ht-150 shadow-sm border-0">
            <Card.Body className="bg-white">
              <Row className="pt-2">
                <Col xs={4}>
                  <img src={passPhotoUrl} className="img-fluid" style={{ borderRadius: 100 }} width={100} alt="..." />
                </Col>
                <Col xs>
                  <h4 className="text-primary mb-0">{user.name}</h4>
                  <div><small className="text-gray">{user.role}</small></div>
                  <div className="text-success text-bold mt-2">{user.companyName ?? "INTERNAL"}</div>
                  <div><i>{user.position}</i></div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={2}>
          <Card className="ht-150 shadow-sm border-0">
            <Card.Body className="bg-white">
              <Link to="/doctor-approval">
                <div className="fs-5 text-nowrap text-truncate">Doctor Approval</div>
                <div className='text-center' style={{ fontSize: 54 }}>{summary.doctorApprovalCount}</div>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-3 mt-4">
        <Col>
          <Card className="shadow border-0">
            <Card.Body className="bg-white">
              <h4 className="pb-3">Medical Check Up Request for Approval</h4>
              <DoctorApproval />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}