import React from "react";
import { useNavigate } from "react-router-dom";
import axios from '../utils/axios';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Helpers from "../utils/Helpers";

export default function LoginPage() {
  const navigate = useNavigate();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isHandleLogin, setIsHandleLogin] = React.useState(false);

  const handleLogin = (event) => {
    if (isHandleLogin) return;
    setIsHandleLogin(true);

    event.preventDefault();

    axios.post('/users/login', { username: username, password: password })
      .then(response => {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("defaultGateId", response.data.user.defaultGateId);
        localStorage.setItem("companyId", response.data.user.companyId);
        localStorage.setItem("role", response.data.user.role);
        localStorage.setItem("expired", Date.now() + response.data.expiry * 1000);

        axios.defaults.headers.common = {'Authorization': `Bearer ${response.data.accessToken}`};
        navigate(window.redirectTo || "/");
        window.redirectTo = null;
      })
      .catch(error => {
        alert(Helpers.parseErrorMessages(error));
        setIsHandleLogin(false);
      });
    };

    const handlePasswordKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleLogin(event);
      }
    }

  return (
    <div className="page-sign" style={{ backgroundImage: "url('/images/background.jpg')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Card className="card-sign">
        <Card.Header>
          <div className="text-center pb-2 mb-2">
          <img src="/images/logo.png" width={100}></img>
          <h4>Digital HSSE Passport</h4>
          </div>

          <Card.Title>Login</Card.Title>
          <Card.Text>Welcome back! Please login to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <div className="mb-4">
            <Form.Label>Username</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            {/* <Form.Label className="d-flex justify-content-between">Password <Link to="" tabIndex="-1">Forgot password?</Link></Form.Label> */}
            <Form.Control 
              type="password" 
              placeholder="Enter your password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handlePasswordKeyDown(e)} 
            />
          </div>
          <Button type="submit" variant="primary" className="btn-sign" onClick={handleLogin} disabled={isHandleLogin}>{isHandleLogin ? "Logging in..." : "Login"}</Button>
        </Card.Body>
      </Card>
    </div>
  )
}