import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axios';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function EditCompanyPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [mode, setMode] = React.useState("Create");
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    if (params.id) {
      setMode("Update");
      axios.get(`/companies/${params.id}`)
        .then((response) => {
          setName(response.data.name);
        });
    } else {
      setMode("Create");
    }
  }, [params.id]);

  const handleSave = async () => {
    const request = {
      name: name
    };

    try {
      if (mode == "Create") {
        await axios.post("/companies", request);
      } else {
        await axios.put(`/companies/${params.id}`, request);
      }

      navigate('/company');
    } catch (error) {
      alert('Failed to save company data: ' + error.response.data.message || error.response.data || error.response);
    }
  }

  const handleCancel = () => {
    navigate('/company');
  }

  return (
    <>
      <Header title={`${mode} Company`} />

      <div className="main main-app p-3 p-lg-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><Link to="/company">Company</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{mode}</li>
          </ol>
          <h4 className="main-title mb-0">{`${mode} Company`}</h4>
        </div>

        <Row className="mt-4">
          <Col md={6}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter company name" value={name} onChange={ (e) => setName(e.target.value) }  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col>
                <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
                <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  )
}