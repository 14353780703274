import React from "react";
import axios from "../utils/axios";
import { Button, ButtonGroup } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import Helpers from "../utils/Helpers";

export default function AdminApproval() {
  const [data, setData] = React.useState([]);
  const user_role = localStorage.getItem("role");

  React.useEffect(() => {
    axios.get('/users/admin-approval/Pending')
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const handleApprove = (id) => {
    axios.post("/users/admin-approve", { id: id })
      .then((response) => {
        setData(data.filter((item) => item.id != id));
      }).catch((error) => {
        alert(Helpers.parseErrorMessages(error));
      });
  }

  const handleReject = (id) => {
    axios.post("/users/admin-reject", { id: id })
      .then((response) => {
        setData(data.filter((item) => item.id != id));
      }).catch((error) => {
        alert(Helpers.parseErrorMessages(error));
      });
  }

  return (
    <>
      <div className="d-flex overflow-auto">
        <Grid
          data={data}
          columns={[
            { id: "id", name: "id", hidden: true },
            {
              id: 'actions',
              hidden: user_role != "Admin",
              name: '', sort: false, formatter: (cell, row) => _(
                  <ButtonGroup>
                    <Button variant="" className="btn-white text-success" size="sm" onClick={() => handleApprove(row.cells[0].data)}>Approve</Button>
                    <Button variant="" className="btn-white text-danger" size="sm" onClick={() => handleReject(row.cells[0].data)}>Reject</Button>
                  </ButtonGroup>
              ),
              width: '100px',
            },
            { id: "username", name: "Username", formatter: (cell, row) => _(<a href={`/user/view/${row.cells[0].data}`}>{cell}</a>) },
            { id: "role", name: "Role" },
            { id: "name", name: "Name" },
            { id: "companyName", name: "Company" },
            { id: "position", name: "Position" },
            { id: "dateCreated", name: "Date Created", formatter: (cell, row) => _(Helpers.displayDateTime(cell + "Z")) },
            { id: "dateUpdated", name: "Date Updated", formatter: (cell, row) => _(Helpers.displayDateTime(cell + "Z")) },
          ]}
          search={true}
          pagination={true}
          sort={true}
          className={{
            table: 'table table-bordered mb-0'
          }}
        />
      </div>
    </>
  )
}