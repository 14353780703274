import React from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import DoctorApproval from "../components/DoctorApproval";

export default function DoctorApprovalPage() {
  return (
    <>
      <Header title="Doctor Approval" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Doctor Approval</li>
            </ol>
            <h4 className="main-title mb-0">Doctor Approval List</h4>
          </div>
        </div>

        <DoctorApproval />

        <Footer />
      </div>
    </>
  )
}