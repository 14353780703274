import React from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Form, Card, Button, Tabs, Tab } from 'react-bootstrap';
import axios from '../utils/axios';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Helpers from '../utils/Helpers';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

export default function BatchUpdateUserPage() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  const columns = [
    { name: 'id', header: 'ID', defaultVisible: false },
    {
      name: 'active',
      header: 'Active',
      defaultFlex: 1,
      render: ({ value, data }) => <Form.Check className="text-center" type="checkbox" checked={value} onChange={handleActive(data.id)} />
    },
    { name: 'username', header: 'Username', defaultFlex: 2 },
    { name: 'role', header: 'Role', defaultFlex: 2 },
    { name: 'name', header: 'Name', defaultFlex: 2 },
    { name: 'position', header: 'Position', defaultFlex: 2 },
  ];

  const filterValue = [
    { name: 'username', operator: 'contains', type: 'string', value: '' },
    { name: 'role', operator: 'contains', type: 'string', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'position', operator: 'contains', type: 'string', value: '' },
  ];

  const gridStyle = { minHeight: 600 };

  React.useEffect(() => {
    axios.get('/users').then((response) => {
      setData(response.data.filter(x => x.screeningStatus == "Permitted to work"));
    });
  }, []);

  const handleActive = (id) => (event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, active: event.target.checked };
      } else {
        return item;
      }
    });
    setData(newData);
  };

  const handleSave = () => {
    const request = data.map((item) => {
      return {
        id: item.id,
        active: item.active
      };
    });

    axios.put('/users/batch', request).then((response) => {
      navigate('/user');
    }
    ).catch((error) => {
      alert('Failed to save data: ' + Helpers.parseErrorMessages(error));
    });
  }

  const handleCancel = () => {
    navigate('/user');
  }

  return (
    <>
      <Header title="Batch Update User" />

      <div className="main main-app p-3 p-lg-4">
        <div className='mb-3'>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><Link to="/user">User</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Batch Update</li>
          </ol>
        </div>

        <div>
          <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
          <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
        </div>

        <div className='mt-3'>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={data}
            defaultFilterValue={filterValue}
            style={gridStyle}
            pagination={true}
            enableColumnAutosize={true}
          />
        </div>

        <Footer />
      </div>
    </>
  )
}
