import React from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../utils/axios';
import Helpers from '../utils/Helpers';
import { Row, Col, Form, Card, Button, Tabs, Tab } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function ViewUserPage() {
  const params = useParams();

  const user_role = localStorage.getItem("role");
  const user_companyId = localStorage.getItem("companyId");

  const [companies, setCompanies] = React.useState([]);

  const [username, setUsername] = React.useState("");
  const [role, setRole] = React.useState("");
  const [name, setName] = React.useState("");
  const [companyId, setCompanyId] = React.useState(user_companyId);
  const [companyName, setCompanyName] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [birthPlace, setBirthPlace] = React.useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [bloodType, setBloodType] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [emergencyContactName, setEmergencyContactName] = React.useState("");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = React.useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = React.useState("");
  const [mcuVerificationNumber, setMcuVerificationNumber] = React.useState("");
  const [mcuResult, setMcuResult] = React.useState("");
  const [mcuNote, setMcuNote] = React.useState("");
  const [mcuValidation, setMcuValidation] = React.useState(null);
  const [mcuDocument, setMcuDocument] = React.useState("");
  const [bpjsTk, setBpjsTk] = React.useState("");
  const [bpjsKesehatan, setBpjsKesehatan] = React.useState("");
  const [startDuty, setStartDuty] = React.useState(null);
  const [endDuty, setEndDuty] = React.useState(null);

  const [ktpUrl, setKtpUrl] = React.useState(null);
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(null);
  const [vaccineBoosterUrl, setVaccineBoosterUrl] = React.useState(null);
  const [mcuDocumentUrl, setMcuDocumentUrl] = React.useState(null);

  React.useEffect(() => {
    axios.get('/companies')
      .then((response) => {
        setCompanies(response.data);

        if (companies.length == 1) {
          setCompanyId(companies[0].id);
        }
      });

    if (params.id) {
      axios.get(`/users/${params.id}`)
        .then((response) => {
          setUsername(response.data.username);
          setRole(response.data.role);
          setName(response.data.name);
          setCompanyId(response.data.companyId);
          setCompanyName(response.data.companyName);
          setPosition(response.data.position);
          setBirthPlace(response.data.birthPlace);
          setBirthDate(Helpers.formatDate(response.data.birthDate + "Z"));
          setGender(response.data.gender);
          setPhoneNumber(response.data.phoneNumber);
          setEmail(response.data.email);
          setAddress(response.data.address);
          setBloodType(response.data.bloodType);
          setNationality(response.data.nationality);
          setEmergencyContactName(response.data.emergencyContactName);
          setEmergencyContactPhoneNumber(response.data.emergencyContactPhoneNumber);
          setEmergencyContactRelation(response.data.emergencyContactRelation);
          setMcuVerificationNumber(response.data.mcuVerificationNumber);
          setMcuResult(response.data.mcuResult);
          setMcuNote(response.data.mcuNote);
          setMcuValidation(Helpers.formatDate(response.data.mcuValidation));
          setMcuDocument(response.data.mcuDocument);
          setBpjsTk(response.data.bpjsTk);
          setBpjsKesehatan(response.data.bpjsKesehatan);
          setStartDuty(Helpers.formatDate(response.data.startDuty));
          setEndDuty(Helpers.formatDate(response.data.endDuty));

          setKtpUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.ktp);
          setPassPhotoUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.passPhoto);
          setVaccineBoosterUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.vaccineBooster);
          setMcuDocumentUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.mcuDocument);
        });
    }
  }, [params.id]);
  return (
    <>
      <Header title="View User" />

      <div className="main main-app p-3 p-lg-4">
        <Row className="mt-3">
          <Col>
            <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={() => window.history.back()}>
              <i className="ri-arrow-left-line fs-18 lh-1"></i>Back
            </Button>
          </Col>
        </Row>

        <Card className="card-form mt-3" style={{ backgroundImage: "url('/images/logo-transparent.png')", backgroundSize:"cover", backgroundPosition: "center", backgroundSize: "900px", backgroundRepeat: "no-repeat" }}>
          <Card.Body>
            <div className="mb-5">
              <h4 className="text-center">PERSONAL IDENTITY</h4>
              <h6 className="text-center">IDENTITAS PRIBADI</h6>
            </div>

            <Row className='pb-4 border-bottom'>
              <Col md>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={name} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4 border-bottom'>
              <Col md={5}>
                <Form.Group>
                  <Form.Label>Birth Place</Form.Label>
                  <Form.Control type="text" value={birthPlace} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group>
                  <Form.Label>Birth Date</Form.Label>
                  <Form.Control type="date" value={birthDate} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Form.Control type="text" value={gender} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4 border-bottom'>
              <Col md>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" value={address} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4 border-bottom'>
              <Col md>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={phoneNumber} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="text" value={email} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4 border-bottom'>
              <Col md>
                <Form.Group>
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control type="text" value={nationality} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group>
                  <Form.Label>Blood Type</Form.Label>
                  <Form.Control type="text" value={bloodType} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4 border-bottom'>
              <Col md>
                <Form.Group>
                  <Form.Label>Emergency Contact Name</Form.Label>
                  <Form.Control type="text" value={emergencyContactName} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group>
                  <Form.Label>Emergency Contact Phone</Form.Label>
                  <Form.Control type="text" value={emergencyContactPhoneNumber} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group>
                  <Form.Label>Emergency Contact Relation</Form.Label>
                  <Form.Control type="text" value={emergencyContactRelation} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='pt-3 pb-4'>
              <Col md>
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control type="text" value={companyName} readOnly={true} />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group>
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" value={position} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className="mt-3">
          <Col>
            <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={() => window.history.back()}>
              <i className="ri-arrow-left-line fs-18 lh-1"></i>Back
            </Button>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  )
}