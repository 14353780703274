import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Grid, _ } from "gridjs-react";
import Helpers from "../utils/Helpers";

export default function DutyHistoryPage() {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.get(`/duty-histories`).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
      <Header title="Company" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Duty History</li>
            </ol>
            <h4 className="main-title mb-0">Duty History</h4>
          </div>
        </div>

        <div className="d-flex overflow-auto">
          <Grid
            data={data}
            columns={[
              { id:"id", name:"id", hidden:true },
              { id:"name", name:"Personnel Name" },
              { id:"position", name:"Position" },
              { id:"companyName", name:"Company" },
              { id:"startDuty", name:"Start Duty", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
              { id:"endDuty", name:"End Duty", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
            ]}
            search={true}
            pagination={true}
            sort={true}
            className={{
              table: 'table table-bordered mb-0'
            }}
          />
        </div>

        <Footer />
      </div>
    </>
  )
}