import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import NotFound from '../pages/NotFound';
import LoginPage from "../pages/LoginPage";
import CompanyPage from '../pages/CompanyPage';
import UserPage from "../pages/UserPage";
import DashboardPage from "../pages/DashboardPage";
import GatePage from '../pages/GatePage';
import EditCompanyPage from '../pages/EditCompanyPage';
import EditGatePage from '../pages/EditGatePage';
import EditUserPage from '../pages/EditUserPage';
import GateInPage from '../pages/GateInPage';
import GateInSubmitPage from '../pages/GateInSubmitPage';
import GateInResultPage from '../pages/GateInResultPage';
import GateOutPage from '../pages/GateOutPage';
import GateOutSubmitPage from '../pages/GateOutSubmitPage';
import GateOutResultPage from '../pages/GateOutResultPage';
import SuperAdminApprovalPage from '../pages/SuperAdminApprovalPage';
import AdminApprovalPage from '../pages/AdminApprovalPage';
import DoctorApprovalPage from '../pages/DoctorApprovalPage';
import DutyHistoryPage from '../pages/DutyHistoryPage';
import SettingPage from '../pages/SettingPage';
import UpdateMedicalPage from '../pages/UpdateMedicalPage';
import ViewUserPage from '../pages/ViewUserPage';
import BatchUpdateUserPage from '../pages/BatchUpdateUserPage';

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: "/dashboard", element: <DashboardPage/> },
        { path: "/company", element: <CompanyPage/> },
        { path: "/company/create", element: <EditCompanyPage/> },
        { path: "/company/update/:id", element: <EditCompanyPage/> },
        { path: "/user", element: <UserPage/> },
        { path: "/user/create", element: <EditUserPage/> },
        { path: "/user/update/:id", element: <EditUserPage/> },
        { path: "/user/view/:id", element: <ViewUserPage/> },
        { path: "/user/medical/:id", element: <UpdateMedicalPage/> },
        { path: "/user/batch", element: <BatchUpdateUserPage/> },
        { path: "/duty-history", element: <DutyHistoryPage/> },
        { path: "/gate", element: <GatePage/> },
        { path: "/gate/create", element: <EditGatePage/> },
        { path: "/gate/update/:id", element: <EditGatePage/> },
        { path: "/gate-in", element: <GateInPage/> },
        { path: "/gate-in/submit", element: <GateInSubmitPage/> },
        { path: "/gate-in/submit/:gateId", element: <GateInSubmitPage/> },
        { path: "/gate-in/result/:id", element: <GateInResultPage/> },
        { path: "/gate-out", element: <GateOutPage/> },
        { path: "/gate-out/submit/:gateId", element: <GateOutSubmitPage/> },
        { path: "/gate-out/submit", element: <GateOutSubmitPage/> },
        { path: "/gate-out/result/:id", element: <GateOutResultPage/> },
        { path: "/superadmin-approval", element: <SuperAdminApprovalPage/> },
        { path: "/admin-approval", element: <AdminApprovalPage/> },
        { path: "/doctor-approval", element: <DoctorApprovalPage/> },
        { path: "/setting", element: <SettingPage/> },
      ]
    },
    {
      path: "/login",
      element: <LoginPage />
    },
    {
      path: "*",
      element: <NotFound/>
    }
  ]);
}