import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../layouts/Header';
import Helpers from '../utils/Helpers';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';

export default function GateInResultPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [result, setResult] = React.useState({});

  React.useEffect(() => {
    const resultJson = localStorage.getItem(params.id);
    console.log(resultJson);
    if (resultJson) {
      setResult(JSON.parse(resultJson));
    }
  }, []);

  return (
    <>
      <Header title="Gate Check In" />

      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Body className="text-center">
            {
              (result.screeningStatus == "Permitted to work") ?
                <img src="/images/permitted.png" className="img-fluid" /> :
                <img src="/images/not-permitted.png" className="img-fluid" />
            }
            
            <h1>{result.screeningStatus}</h1>
            <h2>{result.gateName}</h2>
            <h3>{result.name}</h3>
            <h4>{Helpers.displayDateTime(result.timestamp + "Z")}</h4>
            <br/>
            <h3>Medical Check Up</h3>
            <h5>Verification No: {result.mcuVerificationNumber}</h5>
            <h5>Result: {result.mcuResult}</h5>
            <h5>Note: {result.mcuNote}</h5>
            <h5>Validation: {result.mcuValidation}</h5>

          </Card.Body>
        </Card>
      </div>
    </>
  )
}