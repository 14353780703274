import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import SuperAdminApproval from "../components/SuperAdminApproval";

export default function SuperAdminApprovalPage() {
  return (
    <>
      <Header title="Super Admin Approval" />

      <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Super Admin Approval</li>
            </ol>
            <h4 className="main-title mb-0">Super Admin Approval List</h4>
          </div>
        </div>

        <SuperAdminApproval />

        <Footer />
      </div>
    </>
  )
}