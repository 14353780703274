import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axios';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function GateOutSubmitPage() {
  const params = useParams();
  const navigate = useNavigate();

  const user_role = localStorage.getItem("role");

  const [gates, setGates] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [gateId, setGateId] = React.useState("");
  const [userId, setUserId] = React.useState("");

  React.useEffect(() => {
    setUserId(localStorage.getItem("userId"));

    axios.get("/gates")
      .then((response) => {
        setGates(response.data);
        if (params.gateId) {
          setGateId(params.gateId);
        }
        else {
          setGateId(localStorage.getItem("defaultGateId"));
        }
      });

    if (user_role === "Contractor Admin") {
      axios.get('/users').then((response) => {
        setUsers(response.data);
      });
    }
  }, []);

  const handleSave = async () => {
    const request = {
      gateId: gateId,
      userId: userId
    };

    try {
      const response = await axios.post("/gate-out/submit", request);
      localStorage.setItem(response.data.id, JSON.stringify(response.data));
      navigate(`/gate-out/result/${response.data.id}`);
    } catch (error) {
      alert('Failed to save gate check out data: ' + error.response.data.message || error.response.data || error.response);
    }
  }

  const handleCancel = () => {
    if (params.gateId) {
      navigate("/login");
    } else {
      navigate('/gate-out');
    }
  }

  return (
    <>
      <Header title="Gate Check In" />

      <div className="main main-app p-3 p-lg-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><Link to="/gate-in">Gate Out</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Submit</li>
          </ol>
          <h4 className="main-title mb-0">Gate Check Out</h4>
        </div>

        <Card className="card-form mt-4">
          <Card.Body className="p-0">
            <div className="form-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Gate</h6>
                </Col>
                <Col md>
                  <Form.Select value={gateId} onChange={(e) => setGateId(e.target.value)} disabled={(params.gateId) ? true : false}>
                    <option value="">-- Select Gate --</option>
                    {gates.map((gate) => (
                      <option key={gate.id} value={gate.id}>{gate.name}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>

            {user_role === "Contractor Admin" &&
              <div className="form-item">
                <Row className="g-2 align-items-center">
                  <Col md="5">
                    <h6>User</h6>
                  </Col>
                  <Col md>
                    <Form.Select value={userId} onChange={(e) => setUserId(e.target.value)}>
                      <option value="">-- Select User --</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            }
          </Card.Body>
        </Card>

        <Row className="mt-3">
          <Col>
            <Button variant="primary" onClick={handleSave}>Check Out</Button>&nbsp;&nbsp;
            <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  )
}