import React from "react";
import { useNavigate } from 'react-router-dom';
import axios from "../utils/axios";
import { Button, ButtonGroup } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import Helpers from "../utils/Helpers";

export default function DoctorApproval() {
  const user_role = localStorage.getItem("role");
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    axios.get('/users/doctor-approval/Pending')
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const handleApprove = (id) => {
    axios.post("/users/doctor-approve", { id: id })
      .then((response) => {
        setData(data.filter((item) => item.id != id));
      }).catch((error) => {
        alert(Helpers.parseErrorMessages(error));
      });
  }

  const handleReject = (id) => {
    axios.post("/users/doctor-reject", { id: id })
      .then((response) => {
        setData(data.filter((item) => item.id != id));
      }).catch((error) => {
        alert(Helpers.parseErrorMessages(error));
      });
  }

  const handleUpdate = (id) => {
    navigate(`/user/medical/${id}`);
  }

  return (
    <>
      <div className="d-flex overflow-auto">
        <Grid
          data={data}
          columns={[
            { id: "id", name: "id", hidden: true },
            {
              id: 'actions',
              hidden: user_role != "Doctor",
              name: '', sort: false, formatter: (cell, row) => _(
                <ButtonGroup>
                  <Button variant="" className="btn-white text-success" size="sm" onClick={() => handleApprove(row.cells[0].data)}>Approve</Button>
                  <Button variant="" className="btn-white text-danger" size="sm" onClick={() => handleReject(row.cells[0].data)}>Reject</Button>
                  <Button variant="" className="btn-white text-primary" size="sm" onClick={() => handleUpdate(row.cells[0].data)}>Update</Button>
                </ButtonGroup>
              ),
              width: '100px',
            },
            { id: "username", name: "Username", formatter: (cell, row) => _(<a href={`/user/view/${row.cells[0].data}`}>{cell}</a>)},
            { id: "name", name: "Name" },
            { id: "dateUpdated", name: "Request Date", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
            { id: "companyName", name: "Company" },
            { id: "position", name: "Position" },
            { id: "mcuVerificationNumber", name: "MCU Verification No" },
            { id: "mcuResult", name: "MCU Result" },
            { id: "mcuNote", name: "MCU Note" },
            { id: "mcuValidation", name: "MCU Validation", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
          ]}
          search={true}
          pagination={true}
          sort={true}
          className={{
            table: 'table table-bordered mb-0'
          }}
        />
      </div>
    </>
  )
}