import React from 'react'
import { Link } from "react-router-dom";
import { Card, Col, Nav, Row, Form } from "react-bootstrap";
import axios from '../utils/axios';
import Helpers from '../utils/Helpers';
import userAvatar from "../assets/img/avatar.png";

export default function PersonnelDashboard() {
  const userId = localStorage.getItem("userId");
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")) ?? {});
  const [ktpUrl, setKtpUrl] = React.useState(null);
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(userAvatar);
  const [vaccineBoosterUrl, setVaccineBoosterUrl] = React.useState(null);

  React.useState(() => {
    axios.get(`/users/${userId}`)
      .then(res => {
        setUser(res.data);
        if (res.data.passPhoto) {
          setKtpUrl(process.env.REACT_APP_BASE_URL + "/documents/" + res.data.ktp);
          setPassPhotoUrl(process.env.REACT_APP_BASE_URL + "/documents/" + res.data.passPhoto);
          setVaccineBoosterUrl(process.env.REACT_APP_BASE_URL + "/documents/" + res.data.vaccineBooster);
        }
      }).catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row className="g-3">

        <Col md={4}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className="bg-white">
              <Row className="pt-2">
                <Col xs={4}>
                  <img src={passPhotoUrl} className="img-fluid" style={{ borderRadius: 100 }} width={100} alt="..." />
                </Col>
                <Col xs>
                  <h4 className="text-primary mb-0">{user.name}</h4>
                  <div><small className="text-gray">{user.role}</small></div>
                  <div className="text-success text-bold mt-2">{user.companyName ?? "INTERNAL"}</div>
                  <div><i>{user.position}</i></div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={user.screeningStatus == "Permitted to work" ? "bg-success text-white" : "bg-danger text-white"}>
              <h5>Screening Status</h5>
              <div>{user.screeningStatus}</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={
              (user.mcuStatus == "Red" || user.mcuResult == "Unfit" || user.mcuResult == "Currently unfit") ? "bg-danger text-white" :
                user.mcuStatus == "Yellow" ? "bg-warning text-white" :
                  "bg-success text-white"}>
              <h5>MCU Status</h5>
              <div className='fs-6 fw-semibold'>{user.mcuResult}</div>
              <div>Valid until: {Helpers.displayDate(user.mcuValidation + "Z")}</div>
              <div>{user.mcuDescription}</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={
              user.dutyStatus == "Red" ? "bg-danger text-white" :
                user.dutyStatus == "Yellow" ? "bg-warning text-white" :
                  "bg-success text-white"}>
              <h5>Duty Status</h5>
              <div>Start: {Helpers.displayDate(user.startDuty + "Z")}</div>
              <div>End: {Helpers.displayDate(user.endDuty + "Z")}</div>
              <div>{user.dutyDescription}</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={
              user.fatigueStatus == "Red" ? "bg-danger text-white" :
                user.fatigueStatus == "Yellow" ? "bg-warning text-white" :
                  "bg-success text-white"}>
              <h5 className="text-white">Fatigue Status</h5>
              <div>{user.fatigueDescription}</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={
              user.ageStatus == "Red" ? "bg-danger text-white" :
                user.ageStatus == "Yellow" ? "bg-warning text-white" :
                  "bg-success text-white"}>
              <h5 className="text-white">Age Status</h5>
              <div>{user.ageDescription}</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="ht-150 shadow border-0">
            <Card.Body className={
              user.dutyStatus == "Red" ? "bg-danger text-white" :
                user.dutyStatus == "Yellow" ? "bg-warning text-white" :
                  "bg-success text-white"}>
              <h5 className="text-white">Working Days</h5>
              <h3>{user.workingDays}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="5">
          <Card className="shadow border-0 mt-3" style={{height:350}}>
            <Card.Body className="bg-white">
              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Name</h6></Col>
                <Col>{user.name}
                </Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Company</h6></Col>
                <Col>{user.companyName}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Position</h6></Col>
                <Col>{user.position}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Birth Place</h6></Col>
                <Col>{user.birthPlace}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Birth Date</h6></Col>
                <Col>{Helpers.displayDate(user.birthDate)}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Gender</h6></Col>
                <Col>{user.gender}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Phone No</h6></Col>
                <Col>{user.phoneNumber}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Address</h6></Col>
                <Col>{user.address}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Blood Type</h6></Col>
                <Col>{user.bloodType}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Nationality</h6></Col>
                <Col>{user.nationality}</Col>
              </Row>

              <Row className="align-items-center mb-1">
                <Col xs="4"><h6>Emergency Contact</h6></Col>
                <Col>{user.emergencyContactPhoneNumber}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="shadow border-0 mt-3" style={{height:350}}>
            <Card.Body className="bg-white">
              <Row className="align-items-center mb-3">
                <Col md="6">
                  <h6>KTP</h6>
                  <img src={ktpUrl} className="img-fluid img-responsive" />
                </Col>

                {/* <Col md="4">
                  <h6>Pas Photo</h6>
                  <img src={passPhotoUrl} className="img-fluid img-responsive" width={150} />
                </Col> */}

                <Col md="6">
                  <h6>Vaccine Booster</h6>
                  <img src={vaccineBoosterUrl} className="img-fluid img-responsive" />
                </Col>
              </Row>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </>
  )
}