import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axios';
import Helpers from '../utils/Helpers';
import { Row, Col, Form, Card, Button, Tabs, Tab } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

export default function EditUserPage() {
  const params = useParams();
  const navigate = useNavigate();

  const user_role = localStorage.getItem("role");
  const user_companyId = localStorage.getItem("companyId");

  const [mode, setMode] = React.useState("Create");
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [companies, setCompanies] = React.useState([]);
  const [gates, setGates] = React.useState([]);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState("");
  const [name, setName] = React.useState("");
  const [defaultGateId, setDefaultGateId] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(user_companyId);
  const [position, setPosition] = React.useState("");
  const [birthPlace, setBirthPlace] = React.useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [bloodType, setBloodType] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [ktp, setKtp] = React.useState("");
  const [passPhoto, setPassPhoto] = React.useState("");
  const [vaccineBooster, setVaccineBooster] = React.useState("");
  const [emergencyContactName, setEmergencyContactName] = React.useState("");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = React.useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = React.useState("");
  const [mcuVerificationNumber, setMcuVerificationNumber] = React.useState("");
  const [mcuResult, setMcuResult] = React.useState("");
  const [mcuNote, setMcuNote] = React.useState("");
  const [mcuValidation, setMcuValidation] = React.useState(null);
  const [mcuDocument, setMcuDocument] = React.useState("");
  const [bpjsTk, setBpjsTk] = React.useState("");

  const [bpjsKesehatan, setBpjsKesehatan] = React.useState("");
  const [skck, setSkck] = React.useState("");
  const [skckValidation, setSkckValidation] = React.useState(null);
  const [startDuty, setStartDuty] = React.useState(null);
  const [endDuty, setEndDuty] = React.useState(null);
  const [active, setActive] = React.useState(true);

  const [ktpFile, setKtpFile] = React.useState(null);
  const [ktpUrl, setKtpUrl] = React.useState(null);

  const [passPhotoFile, setPassPhotoFile] = React.useState(null);
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(null);

  const [vaccineBoosterFile, setVaccineBoosterFile] = React.useState(null);
  const [vaccineBoosterUrl, setVaccineBoosterUrl] = React.useState(null);

  const [mcuDocumentFile, setMcuDocumentFile] = React.useState(null);
  const [mcuDocumentUrl, setMcuDocumentUrl] = React.useState(null);
  const [mcuDocumentHistory, setMcuDocumentHistory] = React.useState([]);

  const [bpjsTkDocument, setBpjsTkDocument] = React.useState("");
  const [bpjsTkDocumentFile, setBpjsTkDocumentFile] = React.useState(null);
  const [bpjsTkDocumentHistory, setBpjsTkDocumentHistory] = React.useState([]);

  const [bpjsKesehatanDocument, setBpjsKesehatanDocument] = React.useState("");
  const [bpjsKesehatanDocumentFile, setBpjsKesehatanDocumentFile] = React.useState(null);
  const [bpjsKesehatanDocumentHistory, setBpjsKesehatanDocumentHistory] = React.useState([]);

  const changeKtpFile = (e) => {
    setKtpFile(e.target.files[0]);
    setKtpUrl(URL.createObjectURL(e.target.files[0]));
  }

  const changePassPhotoFile = (e) => {
    setPassPhotoFile(e.target.files[0]);
    setPassPhotoUrl(URL.createObjectURL(e.target.files[0]));
  }

  const changeVaccineBoosterFile = (e) => {
    setVaccineBoosterFile(e.target.files[0]);
    setVaccineBoosterUrl(URL.createObjectURL(e.target.files[0]));
  }

  const changeMcuDocumentFile = (e) => {
    setMcuDocumentFile(e.target.files[0]);
    setMcuDocumentUrl(URL.createObjectURL(e.target.files[0]));
  }

  const changeBpjsTkDocumentFile = (e) => {
    setBpjsTkDocumentFile(e.target.files[0]);
  }

  const changeBpjsKesehatanDocumentFile = (e) => {
    setBpjsKesehatanDocumentFile(e.target.files[0]);
  }


  React.useEffect(() => {
    axios.get('/companies')
      .then((response) => {
        setCompanies(response.data);

        if (companies.length == 1) {
          setCompanyId(companies[0].id);
        }
      });

    axios.get('/gates')
      .then((response) => {
        setGates(response.data);
      });

    if (params.id) {
      setMode("Update");
      axios.get(`/users/${params.id}`)
        .then((response) => {
          setUsername(response.data.username);
          setPassword(response.data.password);
          setRole(response.data.role);
          setName(response.data.name);
          setDefaultGateId(response.data.defaultGateId);
          setCompanyId(response.data.companyId);
          setPosition(response.data.position);
          setBirthPlace(response.data.birthPlace);
          setBirthDate(Helpers.formatDate(response.data.birthDate + "Z"));
          setGender(response.data.gender);
          setPhoneNumber(response.data.phoneNumber);
          setEmail(response.data.email);
          setAddress(response.data.address);
          setBloodType(response.data.bloodType);
          setNationality(response.data.nationality);
          setKtp(response.data.ktp);
          setPassPhoto(response.data.passPhoto);
          setVaccineBooster(response.data.vaccineBooster);
          setEmergencyContactName(response.data.emergencyContactName);
          setEmergencyContactPhoneNumber(response.data.emergencyContactPhoneNumber);
          setEmergencyContactRelation(response.data.emergencyContactRelation);
          setMcuVerificationNumber(response.data.mcuVerificationNumber);
          setMcuResult(response.data.mcuResult);
          setMcuNote(response.data.mcuNote);
          setMcuValidation(Helpers.formatDate(response.data.mcuValidation));
          setMcuDocument(response.data.mcuDocument);
          setBpjsTk(response.data.bpjsTk);
          setBpjsKesehatan(response.data.bpjsKesehatan);
          setSkck(response.data.skck);
          setSkckValidation(Helpers.formatDate(response.data.skckValidation));
          setStartDuty(Helpers.formatDate(response.data.startDuty));
          setEndDuty(Helpers.formatDate(response.data.endDuty));
          setActive(response.data.active);

          setKtpUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.ktp);
          setPassPhotoUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.passPhoto);
          setVaccineBoosterUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.vaccineBooster);
          setMcuDocumentUrl(process.env.REACT_APP_BASE_URL + "/documents/" + response.data.mcuDocument);
        });

      axios.get(`/documents/history?userId=${params.id}&type=1`)
        .then((response) => {
          setMcuDocumentHistory(response.data);
        });

      axios.get(`/documents/history?userId=${params.id}&type=2`)
        .then((response) => {
          setBpjsTkDocumentHistory(response.data);
        });

      axios.get(`/documents/history?userId=${params.id}&type=3`)
        .then((response) => {
          setBpjsKesehatanDocumentHistory(response.data);
        });
    } else {
      setMode("Create");
    }
  }, [params.id]);

  const handleStartDuty = (value) => {
    setStartDuty(value);
    let endDate = new Date(value);
    endDate.setDate(endDate.getDate() + 28);
    setEndDuty(Helpers.formatDate(endDate));
  };

  const handleSave = async () => {
    try {
      const request = {
        username: username,
        password: password,
        role: role,
        name: name,
        defaultGateId: defaultGateId,
        companyId: companyId == "null" ? null : companyId,
        position: position,
        birthPlace: birthPlace,
        birthDate: birthDate,
        gender: gender,
        phoneNumber: phoneNumber,
        email: email,
        address: address,
        bloodType: bloodType,
        nationality: nationality,
        ktp: ktp,
        passPhoto: passPhoto,
        vaccineBooster: vaccineBooster,
        emergencyContactName: emergencyContactName,
        emergencyContactPhoneNumber: emergencyContactPhoneNumber,
        emergencyContactRelation: emergencyContactRelation,
        mcuVerificationNumber: mcuVerificationNumber,
        mcuResult: mcuResult,
        mcuNote: mcuNote,
        mcuValidation: mcuValidation,
        mcuDocument: mcuDocument,
        bpjsTk: bpjsTk,
        bpjsKesehatan: bpjsKesehatan,
        skck: skck,
        skckValidation: skckValidation,
        startDuty: startDuty,
        endDuty: endDuty,
        active: active
      }

      const formDataHeaders = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if (ktpFile) {
        const formDataKtp = new FormData();
        formDataKtp.append('file', ktpFile);
        const responseKtp = await axios.post('/documents/upload', formDataKtp, formDataHeaders);
        request.ktp = responseKtp.data;
      }

      if (passPhotoFile) {
        const formDataPassPhoto = new FormData();
        formDataPassPhoto.append('file', passPhotoFile);
        const responsePassPhoto = await axios.post('/documents/upload', formDataPassPhoto, formDataHeaders);
        request.passPhoto = responsePassPhoto.data;
      }

      if (vaccineBoosterFile) {
        const formDataVaccineBooster = new FormData();
        formDataVaccineBooster.append('file', vaccineBoosterFile);
        const responseVaccineBooster = await axios.post('/documents/upload', formDataVaccineBooster, formDataHeaders);
        request.vaccineBooster = responseVaccineBooster.data;
      }

      if (mcuDocumentFile) {
        const formDataMcuDocument = new FormData();
        formDataMcuDocument.append('file', mcuDocumentFile);
        const responseMcuDocument = await axios.post('/documents/upload', formDataMcuDocument, formDataHeaders);
        request.mcuDocument = responseMcuDocument.data;
      }

      if (bpjsTkDocumentFile) {
        const formDataBpjsTkDocument = new FormData();
        formDataBpjsTkDocument.append('file', bpjsTkDocumentFile);
        const responseBpjsTkDocument = await axios.post('/documents/upload', formDataBpjsTkDocument, formDataHeaders);
        request.bpjsTkDocument = responseBpjsTkDocument.data;
      }

      if (bpjsKesehatanDocumentFile) {
        const formDataBpjsKesehatanDocument = new FormData();
        formDataBpjsKesehatanDocument.append('file', bpjsKesehatanDocumentFile);
        const responseBpjsKesehatanDocument = await axios.post('/documents/upload', formDataBpjsKesehatanDocument, formDataHeaders);
        request.bpjsKesehatanDocument = responseBpjsKesehatanDocument.data;
      }

      if (mode == "Create") {
        await axios.post('/users', request);
      } else {
        await axios.put(`/users/${params.id}`, request);
      }

      if (user_role == "Personnel" || user_role == "Visitor") {
        navigate('/dashboard');
      }
      else {
        navigate('/user');
      }

    } catch (error) {
      alert(Helpers.parseErrorMessages(error));
    }
  }

  const handleCancel = () => {
    if (user_role == "Personnel" || user_role == "Visitor") {
      navigate('/dashboard');
    }
    else {
      navigate('/user');
    }
  }

  return (
    <>
      <Header title={`${mode} User`} />

      <div className="main main-app p-3 p-lg-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><Link to="/user">User</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{mode}</li>
          </ol>
          <h4 className="main-title mb-0">{`${mode} User `}<span className="text-secondary">{name}</span></h4>
        </div>

        <Tabs defaultActiveKey={1} className="mt-4">
          <Tab eventKey={1} title=" General ">
            <Card className="card-form mt-3">
              <Card.Body className="p-0">
                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Username<span className="text-danger">*</span></h6>
                      <p>Leave it blank to autofill with phone number</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Password<span className="text-danger">*</span></h6>
                      <p>Leave it blank to autofill with birth date (ddMMyyyy)</p>
                      <Link to="#" tabIndex="-1" onClick={toggleShowPassword}>{showPassword ? "Hide" : "Show"}</Link>
                    </Col>
                    <Col md>
                      <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Role<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Select value={role} onChange={(e) => setRole(e.target.value)} disabled={user_role == "Personnel" || user_role == "Visitor"}>
                        <option value="">-- Select Role --</option>
                        {user_role == "Super Admin" && <option value="Super Admin">Super Admin</option>}
                        {user_role == "Super Admin" && <option value="Admin">Admin</option>}
                        {(user_role == "Super Admin" || user_role == "Admin" || user_role == "Doctor") && <option value="Doctor">Doctor</option>}
                        {(user_role == "Super Admin" || user_role == "Admin" || (user_role == "Contractor Admin" && role == "Contractor Admin")) && <option value="Contractor Admin">Contractor Admin</option>}
                        {(user_role == "Super Admin" || user_role == "Admin" || user_role == "Contractor Admin" || user_role == "Personnel") && <option value="Personnel">Personnel</option>}
                        {(user_role == "Super Admin" || user_role == "Admin" || user_role == "Contractor Admin" || user_role == "Visitor") && <option value="Visitor">Visitor</option>}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>

                {role == "Personnel" && <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Default Gate</h6>
                    </Col>
                    <Col md>
                      <Form.Select value={defaultGateId} onChange={(e) => setDefaultGateId(e.target.value)} disabled={(params.gateId) ? true : false}>
                        <option value="">-- Select Default Gate --</option>
                        {gates.map((gate) => (
                          <option key={gate.id} value={gate.id}>{gate.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>}

                {role == "Personnel" && <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Start Duty</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="date" value={startDuty} onChange={(e) => handleStartDuty(e.target.value)} disabled={user_role == "Personnel" || user_role == "Visitor"} />
                    </Col>
                  </Row>
                </div>}

                {role == "Personnel" && <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>End Duty</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="date" value={endDuty} onChange={(e) => setEndDuty(e.target.value)} disabled={user_role == "Personnel" || user_role == "Visitor" || user_role == "Contractor Admin"} />
                    </Col>
                  </Row>
                </div>}

                <div className="form-item">
                  <Row className="g-2">
                    <Col>
                      <Form.Check type="checkbox" label="Active" checked={active} onChange={() => setActive(!active)} disabled={user_role == "Personnel" || user_role == "Visitor"} />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey={2} title="Personal">
            <Card className="card-form mt-3">
              <Card.Body className="p-0">
                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Name<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Company<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Select value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                        {user_companyId == "null" && <option value="null">-- INTERNAL --</option>}
                        {companies.map((company) => (
                          <option key={company.id} value={company.id}>{company.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Position<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Position" value={position} onChange={(e) => setPosition(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Birth Place<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Birth Place" value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Birth Date<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="date" placeholder="Birth Date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Gender<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Select value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="">-- Select Gender --</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Phone Number<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Email<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Address<span className="text-danger">*</span></h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Blood Type</h6>
                    </Col>
                    <Col md>
                      <Form.Select value={bloodType} onChange={(e) => setBloodType(e.target.value)}>
                        <option value="">-- Select Blood Type --</option>
                        <option value="A+">A+</option>
                        <option value="B+">B+</option>
                        <option value="AB+">AB+</option>
                        <option value="O+">O+</option>
                        <option value="A-">A-</option>
                        <option value="B-">B-</option>
                        <option value="AB-">AB-</option>
                        <option value="O-">O-</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Nationality</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Nationality" value={nationality} onChange={(e) => setNationality(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>KTP</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="file" onChange={(e) => changeKtpFile(e)} />
                      <img src={ktpUrl} className="img-fluid img-responsive" width={150} accept=".png,.jpg,.jpeg" />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Pas Photo</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="file" onChange={(e) => changePassPhotoFile(e)} />
                      <img src={passPhotoUrl} className="img-fluid img-responsive" width={150} accept=".png,.jpg,.jpeg" />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Vaccine Booster</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="file" onChange={(e) => changeVaccineBoosterFile(e)} />
                      <img src={vaccineBoosterUrl} className="img-fluid img-responsive" width={150} accept=".png,.jpg,.jpeg" />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Tab>

          {role == "Personnel" && <Tab eventKey={3} title="Emergency">
            <Card className="card-form mt-3">
              <Card.Body className="p-0">
                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Emergency Contact Name</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Emergency Contact Name" value={emergencyContactName} onChange={(e) => setEmergencyContactName(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Emergency Contact Phone</h6>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Emergency Contact Phone" value={emergencyContactPhoneNumber} onChange={(e) => setEmergencyContactPhoneNumber(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Emergency Contact Relation</h6>
                    </Col>
                    <Col md>
                      <Form.Control
                        type="text"
                        placeholder="Emergency Contact Relation"
                        value={emergencyContactRelation}
                        onChange={(e) => setEmergencyContactRelation(e.target.value)} />
                    </Col>
                  </Row>
                </div>

              </Card.Body>
            </Card>
          </Tab>}

          {role == "Personnel" && <Tab eventKey={4} title="Medical">
            <Card className="card-form mt-3">
              <Card.Body className="p-0">
                {mode == "Update" &&
                  <>
                    <div className="form-item">
                      <Row className="g-2 align-items-center">
                        <Col md="5">
                          <h6>MCU Verification Number</h6>
                          <p>Medical check up verification number</p>
                        </Col>
                        <Col md>
                          <Form.Control
                            type="text"
                            value={mcuVerificationNumber}
                            onChange={(e) => setMcuVerificationNumber(e.target.value)}
                            disabled={user_role == "Visitor" || user_role == "Personnel" || user_role == "Contractor Admin"} />
                        </Col>
                      </Row>
                    </div>

                    <div className="form-item">
                      <Row className="g-2 align-items-center">
                        <Col md="5">
                          <h6>MCU Result</h6>
                          <p>Medical check up result</p>
                        </Col>
                        <Col md>
                          <Form.Select value={mcuResult} onChange={(e) => setMcuResult(e.target.value)} disabled={user_role == "Visitor" || user_role == "Personnel" || user_role == "Contractor Admin"}>
                            <option value="">-- Select MCU Result --</option>
                            <option value="Fit">Fit</option>
                            <option value="Fit with note">Fit with note</option>
                            <option value="Fit with recommendation">Fit with recommendation</option>
                            <option value="Currently unfit">Currently unfit</option>
                            <option value="Unfit">Unfit</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>

                    <div className="form-item">
                      <Row className="g-2 align-items-center">
                        <Col md="5">
                          <h6>MCU Note</h6>
                          <p>Medical check up note</p>
                        </Col>
                        <Col md>
                          <Form.Control as="textarea" rows="5" value={mcuNote} onChange={(e) => setMcuNote(e.target.value)} disabled={user_role == "Visitor" || user_role == "Personnel" || user_role == "Contractor Admin"} />
                        </Col>
                      </Row>
                    </div>

                    <div className="form-item">
                      <Row className="g-2 align-items-center">
                        <Col md="5">
                          <h6>MCU Validation</h6>
                          <p>Medical valid date expiry</p>
                        </Col>
                        <Col md>
                          <Form.Control type="date" value={mcuValidation} onChange={(e) => setMcuValidation(e.target.value)} disabled={user_role == "Visitor" || user_role == "Personnel" || user_role == "Contractor Admin"} />
                        </Col>
                      </Row>
                    </div>
                  </>}

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>MCU Document</h6>
                      <p>Medical check-up document</p>
                    </Col>
                    <Col md>
                      <div>
                        <Form.Control type="file" onChange={(e) => changeMcuDocumentFile(e)} accept=".pdf,.docx" />
                      </div>
                      {
                        mcuDocumentHistory.map(x => (
                          <div className='mt-2' key={x.documentId}>
                            <a href={process.env.REACT_APP_BASE_URL + "/documents/" + x.documentId}>{Helpers.displayDate(x.date)} - {x.documentName}</a>
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>BPJS TK</h6>
                    </Col>
                    <Col md>
                      <div>
                        <Form.Control type="text" placeholder="BPJS TK" value={bpjsTk} onChange={(e) => setBpjsTk(e.target.value)} />
                      </div>
                      <div className="mt-2">
                        <Form.Control type="file" onChange={(e) => changeBpjsTkDocumentFile(e)} accept=".pdf,.docx" />
                      </div>
                      {
                        bpjsTkDocumentHistory.map(x => (
                          <div className='mt-2' key={x.documentId}>
                            <a href={process.env.REACT_APP_BASE_URL + "/documents/" + x.documentId}>{Helpers.displayDate(x.date)} - {x.documentName}</a>
                          </div>
                        ))
                      }

                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>BPJS Kesehatan</h6>
                    </Col>
                    <Col md>
                      <div>
                        <Form.Control type="text" placeholder="BPJS Kesehatan" value={bpjsKesehatan} onChange={(e) => setBpjsKesehatan(e.target.value)} />
                      </div>
                      <div className="mt-2">
                        <Form.Control type="file" onChange={(e) => changeBpjsKesehatanDocumentFile(e)} accept=".pdf,.docx" />
                      </div>
                      {
                        bpjsKesehatanDocumentHistory.map(x => (
                          <div className='mt-2' key={x.documentId}>
                            <a href={process.env.REACT_APP_BASE_URL + "/documents/" + x.documentId}>{Helpers.displayDate(x.date)} - {x.documentName}</a>
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Tab>}

          {role == "Personnel" && <Tab eventKey={5} title="Others">
            <Card className="card-form mt-3">
              <Card.Body className="p-0">
                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>SKCK</h6>
                      <p>Surat Keterangan Catatan Kepolisian</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" value={skck} onChange={(e) => setSkck(e.target.value)} />
                    </Col>
                  </Row>
                </div>

                <div className="form-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>SKCK Validation</h6>
                      <p>SKCK expiry date</p>
                    </Col>
                    <Col md>
                      <Form.Control type="date" value={skckValidation} onChange={(e) => setSkckValidation(e.target.value)} />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Tab>}
        </Tabs>

        <Row className="mt-3">
          <Col>
            <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
            <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  )
}