import React from "react"
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/img/avatar.png";
import axios from '../utils/axios';
import Helpers from "../utils/Helpers";

export default function Header({ onSkin, title }) {
  const [userId, setUserId] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [roleName, setRoleName] = React.useState("");
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(userAvatar);

  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    const userJson = localStorage.getItem("user");
    if (userJson) {
      const user = JSON.parse(userJson);
      setUserId(user.id);
      setUsername(user.username);
      setRoleName(user.roleName);
      if (user.passPhoto) {
        setPassPhotoUrl(`${process.env.REACT_APP_BASE_URL}/documents/${user.passPhoto}`);
      }
    }

    axios.get("/notifications")
      .then((response) => {
        setNotifications(response.data);
      });
  }, []);

  const handleNotificationClick = (item) => () => {
    if (item.isRead) return;
    axios.put(`/notifications/${item.id}`)
      .then(() => {
        // set the isRead to true
        setNotifications(notifications.map((notification) => {
          if (notification.id === item.id) {
            notification.isRead = true;
          }
          return notification;
        }));
      });
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/login";
  }

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className="me-auto pt-2">
        <h5 id="appbar-title">{title}</h5>
      </div>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          {notifications?.filter(x => !x.isRead).length > 0 && <small>{notifications?.filter(x => !x.isRead).length}</small>}
          <i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>

          <ul className="list-group">
            {notifications?.length == 0 && <li className="list-group-item"><div className="list-group-body"><p>No notifications</p></div></li>}

            {notifications.map((item, key) => {
              return (
                <li className="list-group-item" key={key} onClick={handleNotificationClick(item) }>
                  <div className="list-group-body">
                    <div className={item.isRead ? "fw-normal" : "fw-bold"}>{item.title}</div>
                    <div><small>{item.body}</small></div>
                    <div className="fw-light"><small>{Helpers.formatDateDiff(item.date + "Z")}</small></div>
                  </div>
                </li>
              )})}
          </ul>

          {/* <div className="dropdown-menu-footer"><Link to="#">Show all Notifications</Link></div> */}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={passPhotoUrl} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={passPhotoUrl} alt="" /></div>
            <h5 className="mb-1 text-dark fw-semibold">{username}</h5>
            <p className="fs-sm text-secondary">{roleName}</p>

            <nav className="nav">
              <Link to={`/user/update/${userId}`}><i className="ri-account-circle-line"></i> Edit Profile</Link>
              <Link to="#" onClick={handleLogout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}