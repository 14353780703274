import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { Button, Row, Col } from "react-bootstrap";
import Header from "../layouts/Header";
import Helpers from "../utils/Helpers";

export default function SettingPage() {
  const navigate = useNavigate();

  const [settings, setSettings] = React.useState([]);

  React.useEffect(() => {
    axios.get('/settings').then((response) => {
      setSettings(response.data);
    });
  }, []);

  const handleEdit = (key, value) => {
    const newSettings = settings.map((setting) => {
      if (setting.key === key) {
        return { ...setting, value };
      }
      return setting;
    });
    setSettings(newSettings);
  };

  const handleSave = () => {
    axios.post('/settings', settings).then((response) => {
      alert("Settings saved");
    }).catch((error) => {
      alert(Helpers.parseErrorMessages(error));
    });
  }

  const handleCancel = () => {
    navigate('/dashboard');
  }

  return (
    <>
      <Header title="Settings" />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Setting</li>
            </ol>
            <h4 className="main-title mb-0">Setting</h4>
          </div>
        </div>

        <div className="mt-3">
          <table className="table table-responsive table-bordered">
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((s) => (
                <tr>
                  <td>{s.key}</td>
                  <td><input type="text" className="form-control" value={s.value} onChange={(e) => handleEdit(s.key, e.target.value) } /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Row className="mt-3">
          <Col>
            <Button variant="primary" onClick={handleSave}>Save Changes</Button>&nbsp;&nbsp;
            <Button variant="" className="btn-white" onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </div>
    </>
  )
}