import React from 'react';
import Header from '../layouts/Header';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import SuperAdminDashboard from '../components/SuperAdminDashboard';
import AdminDashboard from '../components/AdminDashboard';
import DoctorDashboard from '../components/DoctorDashboard';
import ContractorAdminDashboard from '../components/ContractorAdminDashboard';
import PersonnelDashboard from '../components/PersonnelDashboard';

export default function DashboardPage() {
  const role = localStorage.getItem("role");

  return (
    <>
      <Header title="Dashboard" />

      <div className="main main-app p-3 p-lg-4">
        {role === "Super Admin" && <SuperAdminDashboard />}
        {role === "Admin" && <AdminDashboard />}
        {role === "Doctor" && <DoctorDashboard />}
        {role === "Contractor Admin" && <ContractorAdminDashboard />}
        {role === "Personnel" && <PersonnelDashboard />}
      </div>
    </>
  )
}