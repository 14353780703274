import React from 'react';
import { Card, Col, Nav, Row } from "react-bootstrap";
import axios from '../utils/axios';
import Helpers from '../utils/Helpers';
import userAvatar from "../assets/img/avatar.png";
import { Grid, _ } from "gridjs-react";
import { Link } from 'react-router-dom';

export default function ContractorAdminDashboard() {
  const userId = localStorage.getItem("userId");
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")) ?? {});
  const [passPhotoUrl, setPassPhotoUrl] = React.useState(userAvatar);
  const [users, setUsers] = React.useState([]);
  const [summary, setSummary] = React.useState({});

  React.useState(() => {
    axios.get(`/users/${userId}`)
      .then(res => {
        setUser(res.data);
        if (res.data.passPhoto) {
          setPassPhotoUrl(process.env.REACT_APP_BASE_URL + "/documents/" + res.data.passPhoto);
        }
      }).catch(err => {
        console.log(err);
      });

    axios.get(`/users`)
      .then(res => {
        setUsers(res.data);
      }).catch(err => {
        console.log(err);
      });

    axios.get("/dashboard/summary")
      .then(res => {
        setSummary(res.data);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row className="g-3">
        <Col lg={4}>
          <Card className="ht-150 shadow-sm border-0">
            <Card.Body className="bg-white">
              <Row>
                <Col xs={4}>
                  <img src={passPhotoUrl} className="img-fluid" style={{ borderRadius: 100 }} width={100} alt="..." />
                </Col>
                <Col xs>
                  <h4 className="text-primary">{user.name}</h4>
                  <div><i>{user.role}</i></div>
                  <div className="text-success text-bold mt-2">{user.companyName ?? "INTERNAL"}</div>
                  <div><i>{user.position}</i></div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={2}>
          <Card className="ht-150 shadow-sm border-0">
            <Card.Body className="bg-white">
              <Link to="/admin-approval">
                <div className="fs-5 text-nowrap text-truncate">Admin Approval</div>
                <div className='text-center' style={{ fontSize: 54 }}>{summary.adminApprovalCount}</div>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={2}>
          <Card className="ht-150 shadow-sm border-0">
            <Card.Body className="bg-white">
              <Link to="/doctor-approval">
                <div className="fs-5 text-nowrap text-truncate">Doctor Approval</div>
                <div className='text-center' style={{ fontSize: 54 }}>{summary.doctorApprovalCount}</div>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col lg={3}>
          <Card className="shadow-sm border-0" style={{ minHeight: 300 }}>
            <Card.Body className="bg-white">
              <h4 className="pb-3">Total Personnel in Gate</h4>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>Gate</th>
                    <th>Personnel</th>
                  </tr>
                </thead>
                <tbody>
                  {summary.personnelGateCount?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.gate}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={9}>
          <Card className="shadow-sm border-0">
            <Card.Body className="bg-white">
              <h4 className="pb-3">Personnel of {user.companyName}</h4>
              <div className="d-flex overflow-auto">
                <Grid
                  data={users}
                  columns={[
                    { id: "id", name: "id", hidden: true },
                    { id: "username", name: "Username", formatter: (cell, row) => _(<a href={`/user/view/${row.cells[0].data}`}>{cell}</a>) },
                    {
                      id: "screeningStatus",
                      name: "Status",
                      formatter: (cell, row) => _(cell == "Permitted to work"
                        ? <span className="badge text-white text-bg-success fs-6">{cell}</span>
                        : <span className="badge text-white text-bg-danger fs-6">{cell}</span>)
                    },
                    { id: "name", name: "Name" },
                    { id: "defaultGateName", name: "Default Gate" },
                    { id: "position", name: "Position" },
                    { id: "gender", name: "Gender" },
                    { id: "startDuty", name: "Start Duty", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
                    { id: "endDuty", name: "End Duty", formatter: (cell, row) => _(Helpers.displayDate(cell)) },
                    { id: "active", name: "Active", formatter: (cell, row) => _(cell ? "Yes" : "No") },
                    { id: "superAdminApprovalStatus", name: "Super Admin Approval" },
                    { id: "adminApprovalStaus", name: "Admin Approval" },
                    { id: "doctorApprovalStatus", name: "Doctor Approval" }
                  ]}
                  autoWidth={true}
                  search={true}
                  pagination={true}
                  sort={true}
                  className={{
                    table: 'table table-bordered mb-0'
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}