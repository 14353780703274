export default class Helpers {
  // convert date to yyyy-MM-dd format
  static formatDate(date) {
    if (date == null) return null;
    
    const d = new Date(date);
    if (isNaN(d)) return null;

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;

    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  // convert date to dd/MM/yyyy format
  static displayDate(date) {
    const d = new Date(date);
    if (isNaN(d)) return null;

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;

    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  // convert date to dd/MM/yyyy HH:mm format
  static displayDateTime(date) {
    const d = new Date(date);
    if (isNaN(d)) return null;

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    let hour = '' + d.getHours();
    let minute = '' + d.getMinutes();
    const timeZone = d.getTimezoneOffset() / 60 * -1;
    const timeZoneName = (timeZone < 0) ? "UTC-" + timeZone : "UTC+" + timeZone;

    if (month.length < 2)
      month = '0' + month;

    if (day.length < 2)
      day = '0' + day;

    if (hour.length < 2)
      hour = '0' + hour;

    if (minute.length < 2)
      minute = '0' + minute;

    return `${day}/${month}/${year} ${hour}:${minute} ${timeZoneName}`;
  }

  // parse error messages from API
  static parseErrorMessages(error) {
    let message = "";
    if (error.response && error.response.data) {
      if (error.response.data.errors) {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          message += `${value}\n`;
        }
      } else if (error.response.data.message) {
        message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    } else {
      message = "Something went wrong. Please try again later.";
    }

    return message;
  }

  static formatDateDiff(date) {
    const diff = Date.now() - Date.parse(date);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "just now";
    }
  }
}